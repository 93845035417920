import { useState } from 'react';

import { LimitInterface } from '../utils/interfaces/table.interface';

export const usePagination = (start: number, end: number): [LimitInterface, (newLimit: LimitInterface) => void] => {
  const [limit, setLimit] = useState<LimitInterface>({
    start,
    end,
  });

  function updatePagination(newLimit: LimitInterface) {
    setLimit(newLimit);
  }

  return [limit, updatePagination];
};
