import { Checkbox } from '@grafana/ui';
import React, { ReactElement, useEffect, useState } from 'react';

import { NotificationQualification } from '@pw-notification';
import { AlarmData } from '@sms-smart-alarm';
import { faL } from '@fortawesome/pro-solid-svg-icons';

export type FilterName = 'alarmDetails' | 'occurrences' | 'duration';

export interface FilterValues {
  // Alarm details
  warningAlarms: boolean;
  errorAlarms: boolean;

  // Occurrences
  occurrencesIncrease: boolean;
  occurrencesDecrease: boolean;

  // Duration
  durationIncrease: boolean;
  durationDecrease: boolean;
}

export const useAlarmFilter = (alarms: AlarmData[]): [AlarmData[], Record<FilterName, ReactElement>] => {
  const [filteredAlarms, setFilteredAlarms] = useState<AlarmData[]>(alarms);
  const [activeFilters, setActiveFilters] = useState<FilterValues>({
    warningAlarms: true,
    errorAlarms: true,
    occurrencesIncrease: true,
    occurrencesDecrease: true,
    durationIncrease: true,
    durationDecrease: true,
  });

  function updateFilter(name: keyof FilterValues) {
    activeFilters[name] = !activeFilters[name];
    setActiveFilters({ ...activeFilters });
  }

  useEffect(() => {
    let filteredAlarms = [...alarms];
    if (!activeFilters.warningAlarms) {
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.qualification !== NotificationQualification.WARNING;
      });
    }
    if (!activeFilters.errorAlarms) {
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.qualification !== NotificationQualification.ERROR;
      });
    }
    if(!activeFilters.occurrencesIncrease){
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.occurrenceTrend.trend <= 0;
      });
    }
    if(!activeFilters.occurrencesDecrease){
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.occurrenceTrend.trend > 0;
      });
    }
    if(!activeFilters.durationIncrease){
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.timeTrend.trend <= 0;
      });
    }
    if(!activeFilters.durationDecrease){
      filteredAlarms = filteredAlarms.filter((alarm) => {
        return alarm.timeTrend.trend > 0;
      });
    }
    setFilteredAlarms(filteredAlarms);
  }, [activeFilters, alarms]);

  function renderAlarmDetailsTooltip() {
    return (
      <>
        <Checkbox
          value={activeFilters.warningAlarms}
          onChange={() => updateFilter('warningAlarms')}
          label={'Warning'}
        />
        <Checkbox value={activeFilters.errorAlarms} onChange={() => updateFilter('errorAlarms')} label={'Error'} />
      </>
    );
  }
  function renderOccurrencesTooltip() {
    return (
      <>
        <Checkbox
          value={activeFilters.occurrencesIncrease}
          onChange={() => updateFilter('occurrencesIncrease')}
          label={'Increase'}
        />
        <Checkbox
          value={activeFilters.occurrencesDecrease}
          onChange={() => updateFilter('occurrencesDecrease')}
          label={'Decrease'}
        />
      </>
    );
  }
  function renderDurationTooltip() {
    return (
      <>
        <Checkbox
          value={activeFilters.durationIncrease}
          onChange={() => updateFilter('durationIncrease')}
          label={'Increase'}
        />
        <Checkbox
          value={activeFilters.durationDecrease}
          onChange={() => updateFilter('durationDecrease')}
          label={'Decrease'}
        />
      </>
    );
  }

  const filterTooltipRecord: Record<FilterName, ReactElement> = {
    alarmDetails: renderAlarmDetailsTooltip(),
    occurrences: renderOccurrencesTooltip(),
    duration: renderDurationTooltip(),
  };

  return [filteredAlarms, filterTooltipRecord];
};
