import React, { FC, ReactElement, useEffect, useState } from 'react';

import classes from './Checkbox.module.scss';

interface CheckboxType {
  toggle: boolean;
  onClick: () => void;
}

const Checkbox: FC<CheckboxType> = ({ toggle, onClick }): ReactElement => {
  const [toggleValue, setToggleValue] = useState<boolean>(toggle);

  useEffect(() => {
    setToggleValue(toggle);
  }, [toggle]);

  return (
    <div className={classes.checkboxInput} onClick={onClick}>
      <input type="checkbox" checked={toggleValue}  onChange={() => setToggleValue((toggleValue) => !toggleValue)} />
    </div>
  );
};

export default Checkbox;
