import { SelectableValue } from '@grafana/data';
import { Icon, Select } from '@grafana/ui';
import React, { FC, ReactElement, useEffect, useState } from 'react';

import { PaginationComponentPropertiesInterface } from '../interfaces/table.interface';

import classes from './PaginationComponent.module.scss';

const options = [
  { label: '8', value: 8 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
];

export const PaginationComponent: FC<PaginationComponentPropertiesInterface> = ({
  updateLimit,
  length,
  defaultLimit,
  watchlist
}: PaginationComponentPropertiesInterface): ReactElement => {
  const [limit, setLimit] = useState<number>(defaultLimit);
  const [page, setPage] = useState<number>(1);
  const [value, setValue] = useState<number>(8);

  function pageLimit(): number {
    return Math.floor(length / limit) + (length % limit > 0 ? 1 : 0);
  }

  function updateSelectLimit(value: number): void {
    setLimit(value);
    setPage(1);
    updateLimit({
      start: 0,
      end: value,
    });
  }

  function updatePage(value: number): void {
    setPage(value);
    updateLimit({
      start: limit * (value - 1),
      end: limit * value,
    });
  }

  useEffect(() => {
    if (watchlist) {
      updatePage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlist]);

  return (
    <div className={classes.pagination}>
      <div className={classes.paginationContent}>
        <div className={classes.items}>
          <Icon name="draggabledots" size="md" />
          <div className={classes.itemsText}>Show</div>
          <Select
            className={classes.selectDropdown}
            options={options}
            value={value}
            defaultValue={10}
            menuPlacement="top"
            closeMenuOnSelect={true}
            autoFocus={false}
            allowCustomValue
            onChange={(customValue: SelectableValue) => {
              setValue(customValue.value);
              updateSelectLimit(customValue.value);
            }}
          />
          <div className={classes.itemsText}>Items</div>
        </div>
        <div className={classes.page}>
          <div className={`${page === 1 ? classes.iconDisabled : ''}`}>
            <Icon name="angle-left" size="xl" onClick={() => updatePage(page - 1)} />
          </div>
          <div className={classes.itemsText}>Page</div>
          <div className={classes.pageNumber}>{page}</div>
          of {pageLimit()}
          <div className={`${!(page < pageLimit()) ? classes.iconDisabled : ''}`}>
            <Icon name="angle-right" size="xl" onClick={() => updatePage(page + 1)} />
          </div>
        </div>
      </div>
    </div>
  );
};
