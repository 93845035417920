import { Icon } from '@grafana/ui';
import React, { FC, ReactElement, useRef, useEffect, ChangeEvent, Dispatch, SetStateAction } from 'react';

import { AlarmTableHeadInterface, MainTableHeadInterface, TableSortType } from '../interfaces/table.interface';

import classes from './AlarmTable.module.scss';

interface HeaderProps {
  order: string;
  name: string;
  setSortType: Dispatch<SetStateAction<TableSortType>>;
}

const MainTableSubHead: FC<MainTableHeadInterface> = ({
  tableSubHead,
  searchTerm,
  setSearchTerm,
  sortType,
  setSortType,
}): ReactElement => {
  const tableSubHeadRef = useRef<HTMLTableRowElement>(null);
  const tableSubHeaderData = tableSubHead as AlarmTableHeadInterface[];

  useEffect(() => {
    if (tableSubHeadRef.current) {
      tableSubHeadRef.current.style.gridTemplateColumns = `repeat(${tableSubHeaderData.length}, calc(100%/${tableSubHeaderData.length}))`;
    }
  }, [tableSubHeaderData.length]);

  return (
    <thead className={classes.alarmTableSubHeader}>
      <tr ref={tableSubHeadRef}>
        {tableSubHeaderData.map((head: AlarmTableHeadInterface, index: number) => (
          <th key={index} className={`${head.type === 'Plant component' ? classes.tableheaderNameInput : ''}`}>
            <div
              className={`${classes.tableSubHeaderName} ${
                head.type === 'Occurrences' || head.type === 'Duration' ? classes.tableSubHeaders : ''
              }`}
            >
              {head.type === 'Plant component' && (
                <>
                  <input
                    type="text"
                    name="search"
                    value={searchTerm}
                    placeholder="Search plant component"
                    className={classes.searchInput}
                    onChange={(event: ChangeEvent) => {
                      if (setSearchTerm) {
                        setSearchTerm((event.target as HTMLInputElement).value);
                      }
                    }}
                  />
                  <Icon name="search" size="sm" color="white" className={classes.icon}></Icon>
                </>
              )}
              {head.type === 'Occurrences' && (
                <OccurrenceHeader order={`${sortType?.order}`} name={`${sortType?.name}`} setSortType={setSortType!} />
              )}
              {head.type === 'Duration' && (
                <DurationHeader order={`${sortType?.order}`} name={`${sortType?.name}`} setSortType={setSortType!} />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const OccurrenceHeader: FC<HeaderProps> = ({ order, name, setSortType }): JSX.Element => {
  return (
    <>
      <div
        className={classes.tableheaderName}
        onClick={() => {
          setSortType({
            name: 'occurrences-amount',
            order: order === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <Icon name="list-ui-alt" size="md" className={classes.listUIAlt}></Icon>
        Amount
        <Icon
          name={`${
            order === 'asc' && name === 'occurrences-amount'
              ? 'angle-left'
              : order === 'desc' && name === 'occurrences-amount'
              ? 'angle-right'
              : 'arrow'
          }`}
          size="md"
          className={classes.arrowIcon}
        ></Icon>
      </div>
      <div
        className={classes.tableheaderName}
        onClick={() => {
          setSortType({
            name: 'occurrences-trend',
            order: order === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <Icon name="list-ui-alt" size="md" className={classes.listUIAlt}></Icon>
        Trend
        <Icon
          name={`${
            order === 'asc' && name === 'occurrences-trend'
              ? 'angle-left'
              : order === 'desc' && name === 'occurrences-trend'
              ? 'angle-right'
              : 'arrow'
          }`}
          size="md"
          className={classes.arrowIcon}
        ></Icon>
      </div>
    </>
  );
};

const DurationHeader: FC<HeaderProps> = ({ order, name, setSortType }): JSX.Element => {
  return (
    <>
      <div
        className={`${classes.tableheaderName} ${classes.duration}`}
        onClick={() => {
          setSortType({
            name: 'duration-average',
            order: order === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <Icon name="list-ui-alt" size="md" className={classes.listUIAlt}></Icon>
        Average
        <Icon
          name={`${
            order === 'asc' && name === 'duration-average'
              ? 'angle-left'
              : order === 'desc' && name === 'duration-average'
              ? 'angle-right'
              : 'arrow'
          }`}
          size="md"
          className={classes.arrowIcon}
        ></Icon>
      </div>
      <div
        className={classes.tableheaderName}
        onClick={() => {
          setSortType({
            name: 'duration-trend',
            order: order === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <Icon name="list-ui-alt" size="md" className={classes.listUIAlt}></Icon>
        Trend
        <Icon
          name={`${
            order === 'asc' && name === 'duration-trend'
              ? 'angle-left'
              : order === 'desc' && name === 'duration-trend'
              ? 'angle-right'
              : 'arrow'
          }`}
          size="md"
          className={classes.arrowIcon}
        ></Icon>
      </div>
    </>
  );
};

export default MainTableSubHead;
