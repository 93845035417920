import { Icon, Tooltip } from '@grafana/ui';
import React, { FC, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { DashboardResponse } from '@sms-smart-alarm';
import { alertError } from '@utils/grafana';

import {
  SmartAlarmDashBoardCallbackContext,
  SmartAlarmDashboardContext,
} from '../../../context/SmartAlarmDashboardContext';
import { useWatchlistFilter } from '../../hooks/watchlistFilter';
import Checkbox from '../Checkbox/Checkbox';
import { AlarmTableBodyInterface, Alarmtable, TableSortType } from '../interfaces/table.interface';
import TrendIcon from '../TrendIcon/TrendIcon';
import { mapTrendDisplay, sortData, toggleSingleAlarmWatchlist, transformDuration, trendTransform } from '../utils';

import classes from './AlarmTable.module.scss';
import MainTableHead from './MainTableHead';
import MainTableSubHead from './MainTableSubHead';

const DASHBOARD_TYPE = 'dashboard';

const AlarmTable: FC<Alarmtable> = ({
  limit,
  tableHead,
  tableSubHead,
  tableBody,
  filters,
  dashboardType,
  columnNumber,
  setTotalAlarms,
}): ReactElement => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortType, setSortType] = useState<TableSortType>({ name: '', order: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tableBodyRef = useRef<HTMLTableRowElement>(null);
  const [tableBodyData, setTableBodyData] = useState<AlarmTableBodyInterface[]>(tableBody);
  const { state, dispatch } = useContext(SmartAlarmDashboardContext);
  const { callbacks, timeRange } = useContext(SmartAlarmDashBoardCallbackContext);
  const { loading, hiddenAlarms, watchlist } = state;
  const [watchlistAlarms] = useWatchlistFilter(watchlist, tableBody);

  if (tableBodyRef.current) {
    tableBodyRef.current.style.gridTemplateColumns = `repeat(${tableHead.length}, calc(100%/${tableHead.length}))`;
  }

  sortData(tableBodyData, sortType);

  const searchPlantComponent = useCallback(() => {
    if (searchTerm.length) {
      const result = tableBody.filter(
        (body: AlarmTableBodyInterface) =>
          body.alarmName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          body.plantComponentName!.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTableBodyData(result);
      setTotalAlarms(result.length);
    } else {
      setTotalAlarms(tableBody.length);
    }
  }, [searchTerm, tableBody, setTotalAlarms]);

  const mappedAlarmTableData = (dashboardResponse: DashboardResponse[]): AlarmTableBodyInterface[] => {
    return dashboardResponse.map((alarm: DashboardResponse) => ({
      alarmName: alarm.description,
      alarmId: alarm.id,
      severity: alarm.severity.toLowerCase(),
      plantComponentName: alarm.hierarchy[0],
      hierarchy: [...alarm.hierarchy.reverse()],
      occurrences: {
        amount: `${alarm.occurrenceTrend.amount}`,
        trend: trendTransform(alarm.occurrenceTrend.trend),
      },
      duration: {
        average: transformDuration(alarm.timeTrend.averageDuration),
        trend: trendTransform(alarm.timeTrend.trend),
      },
      showCheckBox: true,
      singleAlarmOccurrences: alarm.occurrences,
      watchlisted: alarm.onWatchlist,
    }));
  };

  const navigateToSingleAlarmView = async (body: AlarmTableBodyInterface): Promise<void> => {
    try {
      if (dashboardType === DASHBOARD_TYPE) {
        setIsLoading(true);
        const result: DashboardResponse[] = (await callbacks?.getSingleAlarmDashboardData(
          `${body.alarmId}`,
          new Date(`${timeRange?.from}`).toISOString(),
          new Date(`${timeRange?.to}`).toISOString()
        )) as DashboardResponse[];
        const response: AlarmTableBodyInterface[] = mappedAlarmTableData(result);
        setIsLoading(false);
        dispatch({
          type: 'updateSingleAlarm',
          payload: {
            singleAlarmOpen: true,
            singleAlarm: response.length ? response[0] : {},
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      alertError('Error accessing "Single Alarm Dashboard".');
    }
  };

  const toggleAlarmWatchlist = async (alarmId: string, type: string): Promise<void> => {
    const alarmDetails = await toggleSingleAlarmWatchlist(alarmId, type, hiddenAlarms, callbacks!, dispatch);
    if (alarmDetails) {
      const alarm: AlarmTableBodyInterface = tableBodyData.find(
        (alarm: AlarmTableBodyInterface) => alarm.alarmId === alarmDetails.alarmId
      ) as AlarmTableBodyInterface;
      Object.assign(alarm, { ...alarm, watchlisted: alarmDetails.onWatchlist as boolean });
      setTableBodyData(tableBodyData);
    }
  };

  const renderSpinner = (): JSX.Element | null => {
    if (isLoading || loading) {
      return (
        <div className={classes.dataLoading}>
          <Icon name="fa fa-spinner" size="xxxl" className={classes.spinner}></Icon>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setTableBodyData(!watchlist ? tableBody : watchlistAlarms!);
    searchPlantComponent();
  }, [tableBody, searchPlantComponent, watchlist, watchlistAlarms]);

  return (
    <div className={classes.alarmTableContainer}>
      {renderSpinner()}
      <table>
        <MainTableHead tableHead={tableHead} filters={filters} dashboardType={dashboardType} />
        <MainTableSubHead
          tableSubHead={tableSubHead}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sortType={sortType}
          setSortType={setSortType}
        />
        {tableBodyData.length > 0 ? (
          <>
            {tableBodyData.slice(limit.start, limit.end).map((body: AlarmTableBodyInterface, index: number) => (
              <tbody key={index} style={{ cursor: `${dashboardType === 'dashboard' ? 'pointer' : 'default'}` }}>
                <tr ref={tableBodyRef}>
                  <td
                    className={classes.alarmBodyName}
                    style={{
                      width: `${100 / columnNumber}%`,
                      gridTemplateColumns: `${dashboardType === 'dashboard' ? '10% 80%' : '5% 80%'}`,
                    }}
                    onClick={() => navigateToSingleAlarmView(body)}
                  >
                    <div
                      className={`${classes.alarmSeverity} ${
                        body.severity === 'error' ? classes.error : classes.warning
                      }`}
                    >
                      <Icon name="exclamation-triangle" size="lg" />
                    </div>
                    <div className={classes.alarmNameBody}>
                      <Tooltip content={`${body.alarmName}`} placement="top-start" theme="info">
                        <div className={classes.alarmName}>{body.alarmName}</div>
                      </Tooltip>
                      {dashboardType === 'dashboard' && <div className={classes.alarmId}>{body.alarmId}</div>}
                      {dashboardType === 'singleAlarm' && <div className={classes.alarmId}></div>}
                    </div>
                  </td>
                  {body.plantComponentName && (
                    <td className={classes.plantComponentBodyName} style={{ width: `${100 / columnNumber}%` }}>
                      <Tooltip content={`${body?.hierarchy?.join(' > ')}`} placement="top-start" theme="info">
                        <div className={classes.plantComponentName}>{body.plantComponentName}</div>
                      </Tooltip>
                    </td>
                  )}
                  <td
                    className={classes.tableKPI}
                    onClick={() => navigateToSingleAlarmView(body)}
                    style={{ width: `${100 / columnNumber}%` }}
                  >
                    <div className={classes.tableheaderName}>
                      <div className={classes.alarmAverage}>{body.occurrences.amount}</div>
                    </div>
                    <div className={classes.tableheaderName}>
                      <div className={classes.trendIcon}>
                        <TrendIcon
                          trendUp={body.occurrences.trend > 0}
                          trendDown={body.occurrences.trend < 0}
                          trendRight={body.occurrences.trend === 0}
                          marginRight="0.9em"
                        />
                      </div>
                      <div>{mapTrendDisplay(body.occurrences.trend)}</div>
                    </div>
                  </td>
                  <td
                    className={classes.tableKPI}
                    onClick={() => navigateToSingleAlarmView(body)}
                    style={{ width: `${100 / columnNumber}%` }}
                  >
                    <div className={classes.tableheaderName}>
                      <div className={classes.alarmAverage}>{body.duration.average}</div>
                    </div>
                    <div className={classes.tableheaderName}>
                      <div className={classes.trendIcon}>
                        <TrendIcon
                          trendUp={body.duration.trend > 0}
                          trendDown={body.duration.trend < 0}
                          trendRight={body.duration.trend === 0}
                          marginRight="0.9em"
                        />
                      </div>
                      <div>{mapTrendDisplay(body.duration.trend)}</div>
                    </div>
                  </td>
                  {body.showCheckBox && (
                    <td style={{ width: `${100 / columnNumber}%` }}>
                      <Checkbox
                        toggle={body.watchlisted}
                        onClick={() => toggleAlarmWatchlist(`${body.alarmId}`, !body.watchlisted ? 'add' : 'remove')}
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            ))}
          </>
        ) : (
          <tbody style={{ cursor: `${dashboardType === 'dashboard' ? 'pointer' : 'default'}`, border: 'none' }}>
            <tr className={classes.emptyRowData}>
              <td>No data available in the selected time range.</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AlarmTable;
