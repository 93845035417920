import { useEffect, useState } from 'react';

import { AlarmTableBodyInterface } from '../utils/interfaces/table.interface';

export const useWatchlistFilter = (
  watchlist: boolean,
  alarms: AlarmTableBodyInterface[]
): [AlarmTableBodyInterface[]] => {
  const [watchlistAlarms, setWatchlistAlarms] = useState<AlarmTableBodyInterface[]>([]);

  useEffect(() => {
    const alarmsWatchlist: AlarmTableBodyInterface[] = watchlist
      ? alarms.filter((alarm: AlarmTableBodyInterface) => alarm.watchlisted)
      : alarms;
    setWatchlistAlarms(alarmsWatchlist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlist]);
  return [watchlistAlarms];
};
