export interface Notification {
  id: number;
  description: string;
  start: string;
  end: string | null;
  acknowledge: string | null;
  qualification: NotificationQualification;
  type: NotificationType;
  dictionary_id: number;
  extra_info: string;
  parameters: string[];
  tag: string;
  isSimpleNotification: true;
  form_to_open?: string;
  alarm_id?: string;
  isPlanned?: boolean;
  hierarchy?: string[];
}

export enum NotificationQualification {
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
}

export enum NotificationType {
  PHENOMENA = 1,
  ALARM = 2,
  TASK = 3,
}

export enum ACKNOWLEDGE_MODE {
  NONE,
  WITH_TIME,
  WITHOUT_TIME,
}

export interface Dictionary {
  dictionaryId: number;
  target: string;
  description: string;
  type: number;
  qualification: number;
  tag: string;
  formToOpen: string | null;
}
