import { PanelProps } from '@grafana/data';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';

import { SmartAlarmDashboardContainer } from './components/SmartAlarmDashboardContainer';
import SmartAlarmDashBoardProvider from './context/SmartAlarmDashboardContext';

export const SmartAlarmDashboardPanel: FC<PanelProps> = (panelProps: PanelProps): ReactElement => {
  const { data } = panelProps;
  const [hiddenAlarms, setHiddenAlarms] = useState<AlarmDetails[]>([]);
  const callbacks: AlarmCallbacks = (data.series[0].fields[0] as any).callbacks;

  const getHiddenAlarms = useCallback(async (): Promise<void> => {
    try {
      const alarms: AlarmDetails[] = await callbacks.getAlarmDetails() as AlarmDetails[];
      setHiddenAlarms(alarms);
    } catch (error) {}
  }, [callbacks]);

  useEffect(() => {
    getHiddenAlarms();
  }, [getHiddenAlarms]);

  return (
    <SmartAlarmDashBoardProvider>
      <SmartAlarmDashboardContainer {...panelProps} hiddenAlarms={hiddenAlarms} />
    </SmartAlarmDashBoardProvider>
  );
};
