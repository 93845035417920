import { DataFrame, Field, PanelData, TimeRange } from '@grafana/data';
import { Icon, Tab, TabContent, TabsBar } from '@grafana/ui';
import React, { FC, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AlarmCallbacks, AlarmData, AlarmDetails } from '@sms-smart-alarm';

import { SmartAlarmDashBoardCallbackContext, SmartAlarmDashboardContext } from '../context/SmartAlarmDashboardContext';
import logo from '../img/logo.svg';

import AlarmDashboard from './dashboard/AlarmDashboard/AlarmDashboard';
import WatchList from './dashboard/AlarmDashboard/WatchList/WatchList';
import { SingleAlarmPanel } from './dashboard/SingleAlarmDashboard/SingleAlarmPanel';
import classes from './SmartAlarmDashboardContainer.module.scss';

const DASHBOARD_ALARMS = 'dashboard alarms';

interface SmartAlarmStateProps {
  loading: boolean;
  showTab: boolean;
}

interface SmartAlarmStreamProps {
  data: PanelData;
  width: number;
  height: number;
  timeRange: TimeRange;
  hiddenAlarms: AlarmDetails[];
}

const getAlarms = (frames: DataFrame[]): AlarmData[] => {
  let alarms: AlarmData[] = [];

  frames.forEach((frame) => {
    if (frame.name !== DASHBOARD_ALARMS) {
      return;
    }

    frame.fields.forEach(() => {
      alarms = frame.fields.reduce<AlarmData[]>((_, field: Field<AlarmData>) => field.values.toArray(), []);
    });
  });

  return alarms;
};

export const SmartAlarmDashboardContainer: FC<SmartAlarmStreamProps> = ({ data, height, timeRange, hiddenAlarms }): ReactElement => {
  const { state, dispatch } = useContext(SmartAlarmDashboardContext);
  const [stateProps, setStateProps] = useState<SmartAlarmStateProps>({
    loading: false,
    showTab: false,
  });
  const ref = useRef<HTMLDivElement>(null);
  const alarms = useMemo(() => getAlarms(data.series), [data.series]);
  const { singleAlarm } = state;
  const callbacks: AlarmCallbacks = (data.series[0].fields[0] as any).callbacks;

  useEffect(() => {
    setStateProps((item: SmartAlarmStateProps) => ({ ...item, loading: data.state !== 'Done' }));
    dispatch({
      type: 'loading',
      payload: data.state !== 'Done',
    });
  }, [data.state, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'hiddenAlarms',
      payload: hiddenAlarms
    });
  }, [dispatch, hiddenAlarms]);

  const renderTabContent = (): JSX.Element | null => {
    if (!stateProps.showTab) {
      return (
        <>
          {!singleAlarm.singleAlarmOpen && <AlarmDashboard alarms={alarms} />}
          {singleAlarm.singleAlarmOpen && <SingleAlarmPanel timeRange={timeRange} />}
        </>
      );
    }
    return null;
  };

  return (
    <SmartAlarmDashBoardCallbackContext.Provider value={{ callbacks, timeRange }}>
      <div ref={ref} className={classes.dashboardPanel} style={{ height }}>
        <img className={classes.logo} src={logo} alt={'Smart Alarm Logo'} data-cy={'smart-alarm-logo'} />
        <div
          className={classes.dashboardPanelContent}
          style={{ pointerEvents: `${stateProps.loading ? 'none' : 'all'}` }}
        >
          <div className={classes.tabs}>
            <TabsBar>
              <Tab
                label={'ALL ALARMS'}
                active={!stateProps.showTab}
                onChangeTab={() => setStateProps((item: SmartAlarmStateProps) => ({ ...item, showTab: false }))}
                data-cy={'all-alarms-tab-button'}
              />
            </TabsBar>
          </div>
          <div className={classes.watchList}>{!stateProps.showTab && <WatchList alarms={alarms} />}</div>
          <div
            className={classes.alarmTable}
          >
            <TabContent>{renderTabContent()}</TabContent>
          </div>
        </div>
      </div>
    </SmartAlarmDashBoardCallbackContext.Provider>
  );
};
