import { TimeRange } from '@grafana/data';
import React, { Context, Dispatch, FC, ReactElement, ReactNode, createContext, useReducer } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';

import { AlarmTableBodyInterface } from '../components/utils/interfaces/table.interface';

export interface SingleAlarmType {
  singleAlarmOpen: boolean;
  singleAlarm: AlarmTableBodyInterface | {};
}

export interface SmartAlarmDashboardType {
  type: string;
  payload: SingleAlarmType | AlarmDetails[] | boolean;
}

interface SmartAlarmDashboardContextProps {
  watchlist: boolean;
  singleAlarm: SingleAlarmType;
  loading: boolean;
  hiddenAlarms: AlarmDetails[];
}

interface SmartAlarmDashBoardCallbackType {
  callbacks: AlarmCallbacks | null;
  timeRange: TimeRange | null;
}

const initialValues = {
  watchlist: false,
  loading: false,
  singleAlarm: {
    singleAlarmOpen: false,
    singleAlarm: {} as AlarmTableBodyInterface,
  },
  hiddenAlarms: [],
};

export const SmartAlarmDashBoardCallbackContext: Context<SmartAlarmDashBoardCallbackType> =
  createContext<SmartAlarmDashBoardCallbackType>({
    callbacks: null,
    timeRange: null,
  });

export const SmartAlarmDashboardContext = createContext<{
  state: SmartAlarmDashboardContextProps;
  dispatch: Dispatch<SmartAlarmDashboardType>;
}>({
  state: initialValues,
  dispatch: () => null,
});

const mainReducer = (
  state: SmartAlarmDashboardContextProps,
  action: SmartAlarmDashboardType
): SmartAlarmDashboardContextProps => ({
  watchlist: stateReducer(state, action).watchlist,
  loading: stateReducer(state, action).loading,
  singleAlarm: stateReducer(state, action).singleAlarm,
  hiddenAlarms: stateReducer(state, action).hiddenAlarms,
});

const SmartAlarmDashBoardProvider: FC<ReactNode> = ({ children }): ReactElement => {
  const [state, dispatch] = useReducer(mainReducer, initialValues);

  return (
    <SmartAlarmDashboardContext.Provider value={{ state, dispatch }}>{children}</SmartAlarmDashboardContext.Provider>
  );
};

const stateReducer = (
  state: SmartAlarmDashboardContextProps,
  action: SmartAlarmDashboardType
): SmartAlarmDashboardContextProps => {
  switch (action.type) {
    case 'toggleWatchlist': {
      return { ...state, watchlist: action.payload as boolean };
    }
    case 'updateSingleAlarm':
      return { ...state, singleAlarm: action.payload as SingleAlarmType };
    case 'hiddenAlarms':
      return { ...state, hiddenAlarms: [...(action.payload as AlarmDetails[])] };
    case 'loading':
      return { ...state, loading: action.payload as boolean };
    default: {
      return state;
    }
  }
};

export default SmartAlarmDashBoardProvider;
