import { TimeRange } from '@grafana/data';
import { Icon } from '@grafana/ui';
import React, { FC, ReactElement, useContext, useState } from 'react';

import {
  SmartAlarmDashBoardCallbackContext,
  SmartAlarmDashboardContext,
} from '../../../context/SmartAlarmDashboardContext';
import { singleTableHead, singleTableSubHead } from '../../../data/single-alarm-table';
import { useOccurrencesPerDay } from '../../hooks/occurrencesPerDay';
import { usePagination } from '../../hooks/pagination';
import Checkbox from '../../utils/Checkbox/Checkbox';
import { AlarmTableBodyInterface } from '../../utils/interfaces/table.interface';
import { PaginationComponent } from '../../utils/Pagination/PaginationComponent';
import AlarmTable from '../../utils/Table/AlarmTable';
import TrendIcon from '../../utils/TrendIcon/TrendIcon';
import { mapTrendDisplay, toggleSingleAlarmWatchlist } from '../../utils/utils';

import classes from './SingleAlarmPanel.module.scss';

interface SingleAlarmType {
  timeRange: TimeRange;
}

export const SingleAlarmPanel: FC<SingleAlarmType> = ({ timeRange }): ReactElement => {
  const start = `${new Date(timeRange.from.toDate()).toLocaleString('en', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })}`;
  const end = `${new Date(timeRange.to.toDate()).toLocaleString('en', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })}`;
  const { state, dispatch } = useContext(SmartAlarmDashboardContext);
  const { callbacks } = useContext(SmartAlarmDashBoardCallbackContext);
  const { singleAlarm, hiddenAlarms } = state;
  const { singleAlarm: singleAlarmDashboard } = singleAlarm;
  const [limit, updateLimit] = usePagination(0, 8);
  const { alarmId, severity, hierarchy, occurrences, duration, watchlisted } =
    singleAlarmDashboard as AlarmTableBodyInterface;
  const singleAlarmOccurrencesPerDay = singleAlarmDashboard as AlarmTableBodyInterface;
  const occurrencesPerDay = useOccurrencesPerDay(singleAlarmOccurrencesPerDay);
  const [totalAlarms, setTotalAlarms] = useState<number>(occurrencesPerDay.length);

  const toggleAlarmWatchlist = async (alarmId: string, type: string): Promise<void> => {
    toggleSingleAlarmWatchlist(alarmId, type, hiddenAlarms, callbacks!, dispatch);
  };

  return (
    <div className={classes.singleAlarmContainer}>
      <div className={classes.alarmTypeContainer}>
        <div className={`${classes.alarmType} ${severity === 'error' ? classes.error : classes.warning}`}>
          <Icon name="exclamation-triangle"></Icon> {`${severity === 'error' ? 'Error' : 'Warning'}`}
        </div>
        <span className={classes.alarmName}>{singleAlarmOccurrencesPerDay.alarmId}</span>
        <span className={classes.pipe}>|</span>
        <span className={classes.title}>{`${occurrences.amount} occurrence${
          parseInt(`${occurrences.amount}`, 10) > 1 ? 's' : ''
        } from ${start} - ${end}`}</span>
      </div>
      <div className={classes['stats-grid']}>
        <div className={classes.statsGridItems}>
          <span>Plant component</span>
          <span>{hierarchy![hierarchy!.length - 1]}</span>
        </div>
        <div className={classes.statsGridItems}>
          <span>Amount of occurrences</span>
          <span>{occurrences.amount}</span>
        </div>
        <div className={classes.statsGridItems}>
          <span>Average duration</span>
          <span>{duration.average}</span>
        </div>
        <div className={classes.statsGridItems}>
          <span>On watchlist</span>
          <span>
            <Checkbox
              toggle={watchlisted}
              onClick={() => toggleAlarmWatchlist(`${alarmId}`, !watchlisted ? 'add' : 'remove')}
            />
          </span>
        </div>
        <div className={classes.statsGridItems}>
          <span>Trend of occurrences</span>
          <span>
            <TrendIcon
              trendUp={occurrences.trend > 0}
              trendDown={occurrences.trend < 0}
              trendRight={occurrences.trend === 0}
              marginRight="0.7em"
            />
            {mapTrendDisplay(occurrences.trend)}
          </span>
        </div>
        <div className={classes.statsGridItems}>
          <span>Trend of duration</span>
          <span>
            <TrendIcon
              trendUp={duration.trend > 0}
              trendDown={duration.trend < 0}
              trendRight={duration.trend === 0}
              marginRight="0.7em"
            />
            {mapTrendDisplay(duration.trend)}
          </span>
        </div>
      </div>
      <div className={classes.alarmNotice}>
        <Icon name="exclamation-circle" className={classes.icon} />
        NOTE: The table only shows days on which the alarm was active. Inactive alarms also have a trend, which is
        considered in the calculation of the average in the overview at the top of the page.
      </div>
      <div className={classes.singleAlarmTable}>
        <AlarmTable
          limit={limit}
          tableHead={singleTableHead}
          tableSubHead={singleTableSubHead}
          tableBody={occurrencesPerDay}
          dashboardType="singleAlarm"
          columnNumber={3}
          setTotalAlarms={setTotalAlarms}
        />
        <PaginationComponent updateLimit={updateLimit} length={totalAlarms} defaultLimit={limit.end} />
      </div>
    </div>
  );
};
