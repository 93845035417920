import { PanelPlugin } from '@grafana/data';

import { SmartAlarmDashboardPanel } from './SmartAlarmDashboardPanel';
import { SmartAlarmDashboardOptions } from './types';

export const plugin = new PanelPlugin<SmartAlarmDashboardOptions>(SmartAlarmDashboardPanel).setPanelOptions(
  (builder) => {
    return builder;
    // .addTextInput({
    //   path: 'text',
    //   name: 'BiNotifications text option',
    //   description: 'Description of panel option',
    //   defaultValue: 'Default value of text input option',
    // })
  }
);
