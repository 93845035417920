import { AppEvents, EventBusExtended } from '@grafana/data';
// @ts-ignore
import appEvents from 'grafana/app/core/app_events';

export const eventBus: EventBusExtended = appEvents;

export const alertWarning = (title: string, message?: string) => {
  eventBus.emit(AppEvents.alertWarning, [title, message]);
};

export const alertError = (title: string, message?: string) => {
  eventBus.emit(AppEvents.alertError, [title, message]);
};

export const alertSuccess = (title: string, message?: string) => {
  eventBus.emit(AppEvents.alertSuccess, [title, message]);
};
