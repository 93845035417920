import { AlarmTableBodyInterface, AlarmTableHeadInterface } from '../components/utils/interfaces/table.interface';

const singleTableHead: AlarmTableHeadInterface[] = [
  {
    type: 'Date',
    iconName: 'filter',
  },
  {
    type: 'Occurrences',
    iconName: 'filter',
  },
  {
    type: 'Duration',
    iconName: 'filter',
  },
];

const singleTableSubHead: AlarmTableHeadInterface[] = [
  {
    type: 'Date',
  },
  {
    type: 'Occurrences',
  },
  {
    type: 'Duration',
  },
];

const singleTableBody: AlarmTableBodyInterface[] = [
  {
    date: 'Nov 08, 2022',
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    watchlisted: false,
  },
  {
    date: 'Nov 07, 2022',
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    watchlisted: false,
  },
  {
    date: 'Nov 06, 2022',
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    watchlisted: false,
  },
  {
    date: 'Nov 05, 2022',
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    watchlisted: false,
  },
  {
    date: 'Nov 04, 2022',
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    watchlisted: false,
  },
];

export { singleTableHead, singleTableSubHead, singleTableBody };
