// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qualification-filter {
  padding: 0 12px;
  transition: color 0.5s, background-color 0.5s;
}

.qualification-filter svg {
  margin-right: 6px;
}

.qualification-filter.warning {
  border-color: #dbac38;
  color: #dbac38;
  background: transparent;
}

.qualification-filter.warning.active {
  background-color: #dbac38;
  color: white;
}

.qualification-filter.seen {
  border-color: grey !important;
}

.qualification-filter.seen.active {
  background-color: grey !important;
}

.qualification-filter.disabled {
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./../../../../libs/notification/src/inputs/NotificationQualificationButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".qualification-filter {\r\n  padding: 0 12px;\r\n  transition: color 0.5s, background-color 0.5s;\r\n}\r\n\r\n.qualification-filter svg {\r\n  margin-right: 6px;\r\n}\r\n\r\n.qualification-filter.warning {\r\n  border-color: #dbac38;\r\n  color: #dbac38;\r\n  background: transparent;\r\n}\r\n\r\n.qualification-filter.warning.active {\r\n  background-color: #dbac38;\r\n  color: white;\r\n}\r\n\r\n.qualification-filter.seen {\r\n  border-color: grey !important;\r\n}\r\n\r\n.qualification-filter.seen.active {\r\n  background-color: grey !important;\r\n}\r\n\r\n.qualification-filter.disabled {\r\n  cursor: not-allowed;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
