import React, { CSSProperties, FC, ReactElement } from 'react';

interface ArrowRightType {
  style: CSSProperties;
}

const ArrowRight: FC<ArrowRightType> = ({ style }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 16 16"
      style={style}
    >
      {' '}
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
        id="mainIconPathAttribute"
        strokeWidth="1"
        stroke="grey"
      ></path>{' '}
    </svg>
  );
};

export default ArrowRight;
