import { Icon } from '@grafana/ui';
import Tippy from '@tippyjs/react';
import React, { FC, ReactElement, useRef, useEffect } from 'react';

import { MainTableHeadInterface, AlarmTableHeadInterface } from '../interfaces/table.interface';

import classes from './AlarmTable.module.scss';

const MainTableHead: FC<MainTableHeadInterface> = ({ tableHead, filters, dashboardType }): ReactElement => {
  const tableHeadRef = useRef<HTMLTableRowElement>(null);
  const tableHeaderData = tableHead as AlarmTableHeadInterface[];

  useEffect(() => {
    if (tableHeadRef.current) {
      tableHeadRef.current.style.gridTemplateColumns = `repeat(${tableHeaderData.length}, calc(100%/${tableHeaderData.length}))`;
    }
  }, [tableHeaderData.length]);

  return (
    <thead className={classes.alarmTableHeader}>
      <tr ref={tableHeadRef}>
        {tableHeaderData.map((head: AlarmTableHeadInterface, index: number) => (
          <th key={head.type || index}>
            <div className={classes.tableheaderName}>
              {head.iconName && (
                <div className={classes.filterLabel}>
                  <Tippy
                    className={classes.tooltipContent}
                    trigger={'click'}
                    content={head.type === 'Alarm Details' ? filters?.alarmDetails! : head.type === 'Occurrences' ? filters?.occurrences! : filters?.duration!}
                    interactive
                    placement='auto'
                  >
                    {dashboardType === 'dashboard' ? (<div><Icon name={`${head.iconName}`} size="md" className={classes.icon}></Icon></div>) : <></>}
                  </Tippy>
                </div>
              )}
              {head.type}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default MainTableHead;
