// eslint-disable-next-line no-restricted-imports
import { orderBy, uniqBy } from 'lodash-es';

import { AlarmTableBodyInterface } from '../utils/interfaces/table.interface';
import { transformDuration, trendTransform } from '../utils/utils';

export const useOccurrencesPerDay = (singleAlarm: AlarmTableBodyInterface): AlarmTableBodyInterface[] => {
  const allSingleAlarmOccurrences: AlarmTableBodyInterface[] = [];

  for (const item of singleAlarm.singleAlarmOccurrences!) {
    if (item.from && item.to) {
      const data: AlarmTableBodyInterface = {
        date: new Date(`${item.from}`),
        alarmName: `${new Date(`${item.from}`).toLocaleString('en', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        })}`,
        alarmId: singleAlarm.alarmId,
        severity: singleAlarm.severity,
        occurrences: {
          amount: `${item.occurenceTrend?.amount}`,
          trend: trendTransform(item.occurenceTrend?.trend!),
        },
        duration: {
          average: transformDuration(parseInt(`${item.timeTrend?.averageDuration}`, 10)),
          trend: trendTransform(item.timeTrend?.trend!),
        },
        watchlisted: singleAlarm.watchlisted,
      };
      allSingleAlarmOccurrences.push(data);
    }
  }
  const uniqOccurrences: AlarmTableBodyInterface[] = uniqBy(allSingleAlarmOccurrences, 'alarmName');
  const sortedOccurrences: AlarmTableBodyInterface[] = orderBy(uniqOccurrences, 'date', 'desc');
  return sortedOccurrences;
};
