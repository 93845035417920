// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { Dispatch } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';
import { alertSuccess, alertError } from '@utils/grafana';

import { SmartAlarmDashboardType } from '../../context/SmartAlarmDashboardContext';

import { AlarmTableBodyInterface, TableSortType } from './interfaces/table.interface';

const JITTER_LIMIT = 0.0055;
const A_WHOLE_DAY = 86400;
const OCCURRENCES_AMOUNT = 'occurrences-amount';
const DURATION_AVERAGE = 'duration-average';
const OCCURRENCES_TREND = 'occurrences-trend';
const DURATION_TREND = 'duration-trend';

export const mapTrendDisplay = (trend: number): string => {
  if (trend > 1e3) {
    return `${'>'} ${1000}%`;
  }
  return `${trend > 0 ? '+' : ''}${trend.toFixed(0)}%`;
};

export const transformDuration = (seconds: number): string => {
  if (!seconds) {
    return 'N/A';
  }
  if (seconds >= A_WHOLE_DAY) {
    return '> 24h';
  } else {
    return moment('2010-01-01').startOf('day').seconds(seconds).format('HH:mm:ss');
  }
};

export const trendTransform = (trendValue: number): number => {
  trendValue = eliminateJitter(trendValue);
  return trendValue * 100;
};

const eliminateJitter = (value: number): number => {
  if (value < JITTER_LIMIT && value > JITTER_LIMIT * -1) {
    return 0;
  }
  return value;
};

export const toggleSingleAlarmWatchlist = async (
  alarmId: string,
  type: string,
  hiddenAlarms: AlarmDetails[],
  callbacks: AlarmCallbacks,
  dispatch: Dispatch<SmartAlarmDashboardType>
): Promise<AlarmDetails | null> => {
  try {
    let watchlistAlarms: AlarmDetails[] = [];
    const result: AlarmDetails =
      type === 'add'
        ? ((await callbacks?.addToWatchlist(alarmId)) as AlarmDetails)
        : ((await callbacks?.removeFromWatchlist(alarmId)) as AlarmDetails);
    watchlistAlarms = hiddenAlarms.filter((alarm: AlarmDetails) => alarm.alarmId !== alarmId);
    watchlistAlarms.push(result as AlarmDetails);
    dispatch({
      type: 'hiddenAlarms',
      payload: watchlistAlarms,
    });
    alertSuccess(`Successfully ${type === 'add' ? 'added' : 'removed'} watchlist alarm.`);
    return result;
  } catch (error) {
    alertError(`Error ${type === 'add' ? 'adding' : 'removing'} watchlist alarm.`);
    return null;
  }
};

export const sortData = (tableBodyData: AlarmTableBodyInterface[], sortType: TableSortType): void => {
  if (sortType.name === OCCURRENCES_AMOUNT) {
    tableBodyData.sort((current: AlarmTableBodyInterface, next: AlarmTableBodyInterface) =>
      sortType.order === 'asc'
        ? parseInt(`${current.occurrences.amount}`, 10) - parseInt(`${next.occurrences.amount}`, 10)
        : parseInt(`${next.occurrences.amount}`, 10) - parseInt(`${current.occurrences.amount}`, 10)
    );
  }

  if (sortType.name === DURATION_AVERAGE) {
    tableBodyData.sort((current: AlarmTableBodyInterface, next: AlarmTableBodyInterface) =>
      sortType.order === 'asc'
        ? `${current.duration.average}`.localeCompare(`${next.duration.average}`)
        : `${next.duration.average}`.localeCompare(`${current.duration.average}`)
    );
  }

  if (sortType.name === OCCURRENCES_TREND) {
    tableBodyData.sort((current: AlarmTableBodyInterface, next: AlarmTableBodyInterface) =>
      sortType.order === 'asc'
        ? parseInt(`${current.occurrences.trend}`, 10) - parseInt(`${next.occurrences.trend}`, 10)
        : parseInt(`${next.occurrences.trend}`, 10) - parseInt(`${current.occurrences.trend}`, 10)
    );
  }

  if (sortType.name === DURATION_TREND) {
    tableBodyData.sort((current: AlarmTableBodyInterface, next: AlarmTableBodyInterface) =>
      sortType.order === 'asc'
        ? parseInt(`${current.duration.trend}`, 10) - parseInt(`${next.duration.trend}`, 10)
        : parseInt(`${next.duration.trend}`, 10) - parseInt(`${current.duration.trend}`, 10)
    );
  }
};
