import { useEffect, useState } from 'react';

import { AlarmData } from '@sms-smart-alarm';

import { transformDuration } from '../utils/utils';

interface Data {
  first: string;
  second: string;
  third: string;
  fourth: string;
  fifth: string;
  sixth: string;
  seventh: string;
}

interface Column {
  id: keyof Data;
  displayName: string;
}
export const useExportCsv = (alarms: AlarmData[]): [Data[], Column[]] => {
  const [datas, setDatas] = useState<Data[]>([]);
  // Columns are static.
  const columns: Column[] = [
    {
      id: 'first',
      displayName: 'Alarm details',
    },
    {
      id: 'second',
      displayName: 'Plant component',
    },
    {
      id: 'third',
      displayName: 'Occurrences Amount',
    },
    {
      id: 'fourth',
      displayName: 'Occurrences Trend',
    },
    {
      id: 'fifth',
      displayName: 'Duration Average',
    },
    {
      id: 'sixth',
      displayName: 'Duration Trend',
    },
    {
      id: 'seventh',
      displayName: 'On Watchlist',
    },
  ];

  // Each time alarms are updated.
  useEffect(() => {
    setDatas(
      // The datas are updated too.
      alarms.reduce((result: Data[], alarm: AlarmData) => {
        result.push({
          first: `${alarm.name} (${'alarm signal here'})`,
          second: alarm.hierarchy[alarm.hierarchy.length - 1],
          third: `${alarm.occurrences.length}`,
          fourth: `${alarm.occurrenceTrend.trend} %`,
          fifth: transformDuration(alarm.timeTrend.averageDuration),
          sixth: `${alarm.timeTrend.trend} %`,
          seventh: alarm.onWatchlist ? 'Yes' : 'No',
        });
        return result;
      }, [])
    );
  }, [alarms]);

  return [datas, columns];
};
