import React, { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';

import { AlarmData, AlarmDetails } from '@sms-smart-alarm';

import { SmartAlarmDashboardContext } from '../../../context/SmartAlarmDashboardContext';
import { tableHead, tableSubHead } from '../../../data/table-dashboard';
import { useAlarmFilter } from '../../hooks/alarmFilter';
import { usePagination } from '../../hooks/pagination';
import { AlarmDashboardProps, AlarmTableBodyInterface } from '../../utils/interfaces/table.interface';
import { PaginationComponent } from '../../utils/Pagination/PaginationComponent';
import AlarmTable from '../../utils/Table/AlarmTable';
import { transformDuration, trendTransform } from '../../utils/utils';

import classes from './AlarmDashboard.module.scss';

const AlarmDashboard: FC<AlarmDashboardProps> = ({ alarms }): ReactElement => {
  const [limit, updateLimit] = usePagination(0, 8);
  const { state } = useContext(SmartAlarmDashboardContext);
  const [tableBodyData, setTableData] = useState<AlarmTableBodyInterface[]>([]);
  const [filteredAlarms, filters] = useAlarmFilter(alarms);
  const [totalAlarms, setTotalAlarms] = useState<number>(filteredAlarms.length);
  const [totalWatchlistAlarms, setTotalWatchlistAlarms] = useState<number>(0);
  const { watchlist, hiddenAlarms } = state;

  const setWatchlistAlarmsTotal = useCallback(
    (alarms: AlarmTableBodyInterface[]): void => {
      const alarmsWatchlist: AlarmTableBodyInterface[] = watchlist
        ? alarms.filter((alarm: AlarmTableBodyInterface) => alarm.watchlisted)
        : [...alarms];
      setTotalWatchlistAlarms(alarmsWatchlist.length);
    },
    [watchlist]
  );

  const mapTableData = useCallback((): void => {
    let result: AlarmTableBodyInterface[] = filteredAlarms.map((alarm: AlarmData) => {
      const hasHiddenAlarm: AlarmDetails | undefined = hiddenAlarms.find((alarmDetails: AlarmDetails) => {
        return (
          alarmDetails.alarmId === alarm.id &&
          (alarmDetails.permanentlyHide || (alarmDetails.hiddenFrom && alarmDetails.hiddenTo))
        );
      });
      return !hasHiddenAlarm
        ? {
            alarmName: alarm.description,
            alarmId: alarm.id,
            severity: alarm.qualification === 2 ? 'warning' : 'error',
            plantComponentName: alarm.hierarchy[0],
            hierarchy: [...alarm.hierarchy.reverse()],
            occurrences: { amount: `${alarm.occurrenceTrend.amount}`, trend: trendTransform(alarm.occurrenceTrend.trend) },
            duration: { average: transformDuration(alarm.timeTrend.averageDuration), trend: trendTransform(alarm.timeTrend.trend) },
            showCheckBox: true,
            singleAlarmOccurrences: alarm.occurrences,
            watchlisted: alarm.onWatchlist,
          }
        : null;
    }) as AlarmTableBodyInterface[];
    result = result.filter((item: AlarmTableBodyInterface | null) => item);
    const newResult: AlarmTableBodyInterface[] = result.map((item: AlarmTableBodyInterface) => {
      const alarms = hiddenAlarms.find((alarm: AlarmDetails) => alarm.alarmId === item.alarmId);
      return alarms ? { ...item, watchlisted: alarms.onWatchlist } : item;
    }) as AlarmTableBodyInterface[];
    setTableData([...newResult]);
    setWatchlistAlarmsTotal(newResult);
  }, [filteredAlarms, hiddenAlarms, setWatchlistAlarmsTotal]);

  useEffect(() => {
    mapTableData();
  }, [mapTableData]);

  return (
    <div className={classes.alarmTableDashboard}>
      <AlarmTable
        limit={limit}
        tableHead={tableHead}
        tableSubHead={tableSubHead}
        tableBody={tableBodyData}
        filters={filters}
        dashboardType="dashboard"
        columnNumber={5}
        setTotalAlarms={setTotalAlarms}
      />
      {tableBodyData.length > 0 ? (
        <PaginationComponent
          updateLimit={updateLimit}
          length={!watchlist ? totalAlarms : totalWatchlistAlarms}
          defaultLimit={limit.end}
          watchlist={watchlist}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AlarmDashboard;
