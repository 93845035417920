import { AlarmTableBodyInterface, AlarmTableHeadInterface } from '../components/utils/interfaces/table.interface';

const tableHead: AlarmTableHeadInterface[] = [
  {
    type: 'Alarm Details',
    iconName: 'filter',
  },
  {
    type: 'Plant component',
  },
  {
    type: 'Occurrences',
    iconName: 'filter',
  },
  {
    type: 'Duration',
    iconName: 'filter',
  },
  {
    type: 'On watchlist',
  },
];

const tableSubHead: AlarmTableHeadInterface[] = [
  {
    type: 'Alarm Details',
  },
  {
    type: 'Plant component',
  },
  {
    type: 'Occurrences',
  },
  {
    type: 'Duration',
  },
  {
    type: 'On watchlist',
  },
];

const tableBody: AlarmTableBodyInterface[] = [
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Blow stop from Lance cooling system',
    alarmId: 202,
    severity: 'error',
    plantComponentName: 'Lance cooling water system',
    occurrences: { amount: '5', trend: 45 },
    duration: { average: '04:07:54', trend: 82 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Tilting Motor-3 in fault',
    alarmId: 114,
    severity: 'warning',
    plantComponentName: 'Tilting drives',
    occurrences: { amount: '63', trend: -2 },
    duration: { average: '00:01:28', trend: -17 },
    showCheckBox: true,
    watchlisted: false,
  },
  {
    alarmName: 'Temperature high at ID FAN, DE',
    alarmId: 345,
    severity: 'error',
    plantComponentName: 'ID Fan Station',
    occurrences: { amount: '63', trend: 0 },
    duration: { average: '00:01:28', trend: 0 },
    showCheckBox: true,
    watchlisted: false,
  },
];

export { tableHead, tableSubHead, tableBody };
