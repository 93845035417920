// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mx0LnPO3hhL4PCAqPYmI{border:1px solid #ccc;max-height:100vh;margin:.5em 1.5em}`, "",{"version":3,"sources":["webpack://./components/dashboard/AlarmDashboard/AlarmDashboard.module.scss"],"names":[],"mappings":"AAEA,sBACE,qBAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["$border-color: #cccccc;\r\n\r\n.alarmTableDashboard {\r\n  border: 1px solid $border-color;\r\n  max-height: 100vh;\r\n  margin: 0.5em 1.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alarmTableDashboard": `mx0LnPO3hhL4PCAqPYmI`
};
export default ___CSS_LOADER_EXPORT___;
