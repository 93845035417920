import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonVariant } from '@grafana/ui';
import React, { CSSProperties, PropsWithChildren } from 'react';

import { NotificationQualification } from '../types';

import './NotificationQualificationButton.css';

export interface Props {
  qualification: NotificationQualification;
  active: boolean;
  seen?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onClick: (qualification: NotificationQualification) => void;
}

const iconByQualification: Record<NotificationQualification, IconDefinition> = {
  [NotificationQualification.INFO]: faInfoCircle,
  [NotificationQualification.WARNING]: faExclamationTriangle,
  [NotificationQualification.ERROR]: faExclamationCircle,
};

const classNameByQualification = {
  [NotificationQualification.INFO]: 'information',
  [NotificationQualification.WARNING]: 'warning',
  [NotificationQualification.ERROR]: 'critical',
};

const buttonVariantByQualification: Record<NotificationQualification, ButtonVariant> = {
  [NotificationQualification.INFO]: 'primary',
  [NotificationQualification.WARNING]: 'secondary',
  [NotificationQualification.ERROR]: 'destructive',
};

export const NotificationQualificationButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  qualification,
  seen,
  disabled,
  active,
  onClick,
  style,
}) => {
  const qualificationClassName = classNameByQualification[qualification];
  const seenClassName = seen === true ? 'seen' : '';
  const disabledClassName = disabled === true ? 'disabled' : '';
  const activeClassName = active === true ? 'active' : '';

  const className = `qualification-filter ${qualificationClassName} ${seenClassName} ${disabledClassName} ${activeClassName} `;

  const onButtonClick = () => {
    if (disabled === true) {
      return;
    }

    onClick(qualification);
  };

  return (
    <Button
      variant={buttonVariantByQualification[qualification]}
      fill={active ? 'solid' : 'outline'}
      type="button"
      className={className}
      onClick={onButtonClick}
      style={{ ...style }}
    >
      <FontAwesomeIcon icon={iconByQualification[qualification]} />
      {children}
    </Button>
  );
};
