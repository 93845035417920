import { Icon } from '@grafana/ui';
import React, { FC, ReactElement, useContext } from 'react';
import CsvDownloader from 'react-csv-downloader';

import { SmartAlarmDashboardContext } from '../../../../context/SmartAlarmDashboardContext';
import { useExportCsv } from '../../../hooks/exportCsv';
import Checkbox from '../../../utils/Checkbox/Checkbox';
import { AlarmDashboardProps } from '../../../utils/interfaces/table.interface';

import classes from './WatchList.module.scss';

const WatchList: FC<AlarmDashboardProps> = ({ alarms }): ReactElement => {
  const { state, dispatch } = useContext(SmartAlarmDashboardContext);
  const { watchlist, singleAlarm } = state;
  const [csvDatas, csvColumns] = useExportCsv(alarms);

  return (
    <div className={classes.watchList}>
      <div
        className={classes.watchListBackButton}
        onClick={() => {
          dispatch({
            type: 'updateSingleAlarm',
            payload: {
              singleAlarmOpen: false,
              singleAlarm: {},
            },
          });
        }}
      >
        {singleAlarm.singleAlarmOpen && (
          <>
            <Icon name="arrow-left" size="xxl" className={classes.arrowLeft}></Icon>
            Go back
          </>
        )}
      </div>
      <div className={classes.watchListContent}>
        {!singleAlarm.singleAlarmOpen && alarms.length > 0 && (
          <>
            <div className={classes.watchListItem}>
              <Checkbox
                toggle={watchlist}
                onClick={() => {
                  dispatch({
                    type: 'toggleWatchlist',
                    payload: !watchlist,
                  });
                }}
              />
              <span>Only show watchlist</span>
            </div>
            <div className={classes.separator}></div>
            <CsvDownloader
              filename="alarms"
              extension=".csv"
              columns={csvColumns}
              datas={csvDatas.map((data) => ({ ...data }))}
              data-cy={'export-csv-button'}
              className={`${classes.watchListItem} ${classes.export}`}
            >
              <Icon name="upload" size="md" className={classes.icon}></Icon>
              <span>Export</span>
            </CsvDownloader>
          </>
        )}
      </div>
    </div>
  );
};

export default WatchList;
